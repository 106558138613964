.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.fifth-width {
  max-width: min(30vw, 30vh, 200px);
}

.centered {
  margin: auto;
}

.video-banner {
  max-height: 500px;
}

.video-wrapper {
  position: relative;
  max-height: inherit;
  overflow: hidden;
}

.video-wrapper > video {
  width: 100%;
  display: block;
  object-fit: cover;
}

.video-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);

  color: #ffffff;
}

.transparent-background {
  background-color: transparent !important;
}

.card-overlay {
  background: rgba(265, 265, 265, 0.6);
  max-height: fit-content;
}

.card-overlay > .title {
  padding-top: 2px;
}

.card-button {
  width: 100%;
}

.content-wrapper {
  flex: 1
}

.has-border {
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.notification-padding {
  padding: 1.25rem 1.25rem 1.25rem 1.25rem !important;
}

.specification-list {
  list-style: square;
}

.home-gallery > .is-half > .image > img {
  padding: 0px 0px 15px 0px;
}

.home-gallery {
  padding: 10px 0px 0px 0px;
}

.coming-soon {
  scale: 0.6;
  position: absolute;
  right: max(-40%);
  top: max(45%);
}

@media screen and (min-width: 781px) {
  .coming-soon {
    transform: rotate(-20deg);
    scale: 0.6;
    position: absolute;
    right: max(-40%);
    top: max(45%);
  }
}


@media screen and (max-width: 780px) {
  .coming-soon {
    transform: rotate(-20deg);
    scale: 0.4;
    position: absolute;
    right: max(-32%);
    top: max(47%);
  }
}

.card {
  max-height: 100%;
}

.tile {
  position: relative;
}

.tile > .image {
  max-width: 100px;
  max-height: 100px;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  opacity: 12%;
}

.tile > .image > img {
  max-width: 100px;
  max-height: 100px;
}